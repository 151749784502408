// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wideTextContainer {
  width: 100%;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thinTextContainer {
  width: 100%;
  row-gap: 1rem;
  align-items: center;
  padding: 1rem 25%;
}

.projectHomeContainer {
  width: 100%;
  padding: 0 var(--project-body-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

@media (max-width: 1200px) {
  .wideTextContainer {
    padding: 0 10%;
  }

  .wideTextContainer p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .thinTextContainer {
    padding: 1rem 10%;
  }

  .projectHomeContainer {
    padding: 0 5%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/body.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".wideTextContainer {\n  width: 100%;\n  padding: 0 20%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.thinTextContainer {\n  width: 100%;\n  row-gap: 1rem;\n  align-items: center;\n  padding: 1rem 25%;\n}\n\n.projectHomeContainer {\n  width: 100%;\n  padding: 0 var(--project-body-padding);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 100%;\n}\n\n@media (max-width: 1200px) {\n  .wideTextContainer {\n    padding: 0 10%;\n  }\n\n  .wideTextContainer p {\n    padding-top: 0.5rem;\n    padding-bottom: 0.5rem;\n  }\n\n  .thinTextContainer {\n    padding: 1rem 10%;\n  }\n\n  .projectHomeContainer {\n    padding: 0 5%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
