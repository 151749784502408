// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/body.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/globals.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/home.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/mathsart.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/projects.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.db-ball{display:block;width:100px;height:100px;border-radius:50%;background:radial-gradient(circle at 60px 30px, #f00, #000);text-align:center;line-height:90px;font-size:2rem;font-family:"Quicksand";font-weight:800;margin-bottom:1rem}.db-ball:hover{background:radial-gradient(circle at 60px 30px, #f40, #000)}.db-ball.db-target{background:radial-gradient(circle at 60px 30px, #f80, #000)}.db-col{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:2rem 1rem}.db-row{display:flex;justify-content:center;align-items:center;user-select:none}.db-drop{position:absolute;width:125px;height:110px}`, "",{"version":3,"sources":["webpack://./src/styles/blog.scss"],"names":[],"mappings":"AAAA,SACI,aAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,2DAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CACA,uBAAA,CACA,eAAA,CACA,kBAAA,CACA,eACI,2DAAA,CAEJ,mBACI,2DAAA,CAIR,QACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,QACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CAGJ,SACI,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".db-ball {\n    display: block;\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    background: radial-gradient(circle at 60px 30px, #f00, #000);\n    text-align: center;\n    line-height: 90px;\n    font-size: 2rem;\n    font-family: 'Quicksand';\n    font-weight: 800;\n    margin-bottom: 1rem;\n    &:hover {\n        background: radial-gradient(circle at 60px 30px, #f40, #000);\n    }\n    &.db-target {\n        background: radial-gradient(circle at 60px 30px, #f80, #000);\n    }\n}\n\n.db-col {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 2rem 1rem;\n}\n\n.db-row {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    user-select: none;\n}\n\n.db-drop {\n    position: absolute;\n    width: 125px;\n    height: 110px;\n    /* background-color: pink; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
